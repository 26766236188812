<template>
  <div class="pb-32">
    <h2 class="text-xl font-bold mb-2">Outgoing Wema Transactions</h2>
    <h3 class="text-sm mt-2 mb-4">Wema Balance: {{ wemaBalance ? '₦ '+parseFloat(wemaBalance).toLocaleString() : 'Updating...'}}</h3>
    <template>
      <div>
        <button
          @click="downloadCSV"
          class="btn-sm btn-blue rounded-sm px-6 mb-2"
        >
          Download CSV
        </button>
      </div>
      <div>
        <div class="border border-solid border-blue-200 pt-4">
          <datatable
            :ajax="true"
            :ajax-pagination="true"
            @ajaxDataFetched="(data) => {
              data.data.page_count = data.data.totalPages;
              data.datatable = data.data;
            }"
            :url="`${this.$baseurl}/admin/virtual-account/transactions/outgoing`"
            :rangeable="wemaTransactions.dateRange"
            :columns="wemaTransactions.columns"
            :limit="25"
            :data="wemaTransactions.data"
            ref="wemaTransactionsTable"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wemaBalance: null,
      wemaTransactions: this.$options.resource([], {
        data: [],

        columns: [
          {
            name: 'id',
            th: 'ID',
          },
          {
            name: 'transactionReference',
            th: 'Transaction Reference',
            sortable: false,
          },
          {
            name: 'debitAccount',
            th: 'Debit Account',
          },
          {
            name: 'creditAccount',
            th: 'Credit Account',
          },
          {
            name: 'amount',
            th: 'Amount',
            render: (transaction) =>
              this.$options.filters.currency(transaction.amount),
          },
          {
            name: 'narration',
            th: 'Narration',
          },
          {
            name: 'vendorID',
            th: 'Vendor ID',
          },
          {
            name: 'transactionType',
            th: 'Transaction Type',
          },
          {
            name: 'finacleRespone',
            th: 'Finacle Respone'
          },
          {
            name: 'datePosted',
            th: 'Date Posted',
            render: (_, datePosted) => this.$options.filters.dateFormat(datePosted)
          },
        ],

        loading: false,
        dateRange: {
          value: {
            startDate: '2023-11-01',
            endDate: '2023-11-30',
          },
          dateParamKeys: ['start_date', 'end_date'],
          format: 'yyyy-mm-dd',
        },
      })
    };
  },
  mounted() {
    this.updateTotalBalance()
  },
  methods: {
    updateTotalBalance() {
      this.sendRequest('admin.statistics.wemaBalance').then((data) => {this.wemaBalance = data.response.data.data.balance})
    },
    downloadCSV() {
      this.parseObjectToCsvFile(this.$refs.wemaTransactionsTable.ajaxItems);
    },
  },
};
</script>
